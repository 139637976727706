import { ref, reactive, toRaw, isReactive } from 'vue'

const filtrationData = reactive({
  category: [], // 选中一级分类列表
  typeObj: {
    type: '', // 选中二级分类列表
    item: {}
  },
  productFeatures: [], // 选中产品特点
  priceRangeObj: {
    priceRange: '', // 选中价格范围
    greaterThanOrEqual: '', // 价格大于等于
    lessThanOrEqual: '' // 价格小于等于
  }, // 选中价格范围
  popularBrands: [], // 选中品牌
  selectedList: []
})

// 重置选项
const filtrationResetData = (type) => {
  if (type === 'category') {
    filtrationData.category = []
  } else if (type === 'type') {
    filtrationData.typeObj = {
      type: '', // 选中二级分类列表
      item: {}
    }
  } else if (type === 'productFeatures') {
    filtrationData.productFeatures = []
  } else if (type === 'priceRange') {
    filtrationData.priceRangeObj = {
      priceRange: '', // 选中价格范围
      greaterThanOrEqual: '', // 价格大于等于
      lessThanOrEqual: '' // 价格小于等于
    }
  } else if (type === 'popularBrands') {
    filtrationData.popularBrands = []
  } else {
    filtrationData.category = []
    filtrationData.typeObj = {
      type: '',
      item: {}
    }
    filtrationData.productFeatures = []
    filtrationData.priceRangeObj = {
      priceRange: '',
      greaterThanOrEqual: '',
      lessThanOrEqual: ''
    }
    filtrationData.popularBrands = []
  }
}

const handleFiltrationRadio = (type, list) => {
  switch (type) {
    case 'type':
      list.find(item => {
        if (item.id === filtrationData.typeObj.type) {
          filtrationData.typeObj.item = item
        }
      })
      console.log(filtrationData.typeObj, 'filtrationData.typeObj')
      break
    case 'priceRange':
      list.find(item => {
        if (item.name === filtrationData.priceRangeObj.priceRange) {
          filtrationData.priceRangeObj.item = item
          // filtrationData.priceRangeObj.greaterThanOrEqual = item.minPrice
          // filtrationData.priceRangeObj.lessThanOrEqual = item.maxPrice
        }
      })
      console.log(filtrationData.priceRangeObj, 'filtrationData.typeObj')
      break
    case 'productFeatures':
      break
    default:
      break
  }
  console.log(filtrationData, 'filtrationData')
}

export { filtrationData, filtrationResetData, handleFiltrationRadio }
