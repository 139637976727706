<script setup>
  import { filtrationData, filtrationResetData, handleFiltrationRadio } from './filtrationFunc'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, nextTick, toRaw, isReactive, inject } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore, HsystemStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const setLoading = inject('setLoading')
  const $emit = defineEmits(['updataList'])

  const props = defineProps({
    queryParms: {
      type: Object,
      default: () => {
        return {}
      }
    }
  })

  const data = reactive({
    loading: false
  })

  const categoryFindObj = reactive({
    categoryList: {}, // 一级分类列表
    typeList: {}, // 二级分类列表
    productFeaturesList: {}, // 产品特点
    priceRangeList: {}, // 价格范围
    popularBrandsList: {} // 品牌
  })

  // 获取左侧类别列表
  const getCategoryFind = (type) => {
    setLoading({ type: 'category', status: true })
    if (type === 'init') {
      filtrationResetData()
    }
    const params = {
      categoryId: props.queryParms.firstCategoryId || props.queryParms.secondCategoryId || props.queryParms.thirdCategoryId || '',
      secondCategoryId: filtrationData.category.map((item) => item.id),
      thirdCategoryId: filtrationData.typeObj.type,
      name: props.queryParms.keyword || ''
    }
    console.log(params, 'params')
    api.product
      .getCategoryFind(params)
      .then((res) => {
        // console.log(res, '获取过滤器列表')
        // 类别列表
        const categoryFindList = Object.keys(res).map((key) => ({ key, value: res[key] }))
        categoryFindList.forEach((item) => {
          if (item.key === 'category') {
            categoryFindObj.categoryList = item.value
          } else if (item.key === 'type') {
            categoryFindObj.typeList = item.value
          } else if (item.key === 'priceRange') {
            categoryFindObj.priceRangeList = item.value
          } else if (item.key === 'productFeatures') {
            categoryFindObj.productFeaturesList = item.value
          } else if (item.key === 'popularBrands') {
            categoryFindObj.popularBrandsList = item.value
          }
        })
        resetSpecificFilters()
        setLoading({ type: 'category', status: false })
        console.log(categoryFindObj, '获取过滤器列表')
      })
      .catch((err) => {
        setLoading({ type: 'category', status: false })
      })
  }

  const iscategoryFindList = () => Object.values(categoryFindObj).some((list) => list.length)

  // 定义一个函数来重置特定的类别
  const resetSpecificFilters = () => {
    if (!categoryFindObj.categoryList.length) {
      filtrationResetData('category')
    }
    if (!categoryFindObj.typeList.length) {
      filtrationResetData('type')
    }
    if (!categoryFindObj.priceRangeList.length) {
      filtrationResetData('priceRange')
    }
    if (!categoryFindObj.productFeaturesList.length) {
      filtrationResetData('productFeatures')
    }
    if (!categoryFindObj.popularBrandsList.length) {
      filtrationResetData('popularBrands')
    }
  }

  // 修改选项
  const handleChange = (type, event) => {
    // console.log(type, event, '修改选项卡')
    switch (type) {
      case 'type':
        handleFiltrationRadio(type, categoryFindObj.typeList)
        break
      case 'priceRange':
        handleFiltrationRadio(type, categoryFindObj.priceRangeList)
        break
      default:
        break
    }
    console.log(categoryFindObj, 'categoryFindObj')
    updataList('change')
  }

  const changeNum = (type) => {
    console.log(type, 'type')
  }

  const updataList = (type) => {
    $emit('updataList', type)
  }

  defineExpose({
    getCategoryFind
  })
</script>

<template>
  <div class="page-box" v-if="iscategoryFindList">
    <div class="shaixuan-card" v-if="categoryFindObj.categoryList.length">
      <span class="sort-bya-title">{{ $t('Category') }}</span>
      <el-checkbox-group v-model="filtrationData.category" @change="handleChange('category', $event)" data-remark="Category一级分类多选">
        <el-checkbox v-for="(item, index) in categoryFindObj.categoryList" :key="index" :label="item" size="small">
          {{ item.name }}
        </el-checkbox>
      </el-checkbox-group>
    </div>

    <div class="shaixuan-card" v-if="categoryFindObj.typeList.length">
      <span class="sort-bya-title">{{ $t('Type') }}</span>
      <el-radio-group v-model="filtrationData.typeObj.type" @change="handleChange('type', $event)" data-remark="Type二级分类单选">
        <el-radio v-for="(item, index) in categoryFindObj.typeList" :key="index" :label="item.id">
          {{ item.name }}
        </el-radio>
      </el-radio-group>
    </div>

    <div class="shaixuan-card" v-if="categoryFindObj.productFeaturesList.length">
      <span class="sort-bya-title">{{ $t('Product Features') }}</span>
      <el-checkbox-group v-model="filtrationData.productFeatures" @change="handleChange('productFeatures', $event)" data-remark="Product Features产品特点多选">
        <el-checkbox v-for="(item, index) in categoryFindObj.productFeaturesList" :key="index" :label="item">
          {{ item.name }}
        </el-checkbox>
      </el-checkbox-group>
    </div>

    <div class="shaixuan-card" v-if="categoryFindObj.priceRangeList.length">
      <span class="sort-bya-title">{{ $t('Price Range') }}</span>
      <div class="shaixuan_card_price flex_box">
        <el-input
          class="price-input"
          :placeholder="$t('Min Price')"
          v-model="filtrationData.priceRangeObj.greaterThanOrEqual"
          type="Number"
          @blur="filtrationData.priceRangeObj.greaterThanOrEqual = $event.target.value.trim()"
          @keyup="changeNum('greaterThanOrEqual')"
        />
        <el-input
          class="price-input"
          :placeholder="$t('Max Price')"
          v-model="filtrationData.priceRangeObj.lessThanOrEqual"
          type="Number"
          @blur="filtrationData.priceRangeObj.lessThanOrEqual = $event.target.value.trim()"
          @keyup="changeNum('lessThanOrEqual')"
        />
        <button class="price-button" @click="updataList">{{ $t('OK') }}</button>
      </div>
      <el-radio-group v-model="filtrationData.priceRangeObj.priceRange" @change="handleChange('priceRange', $event)" data-remark="Price Range价格范围单选">
        <el-radio v-for="(item, index) in categoryFindObj.priceRangeList" :key="index" :label="item.name">
          {{ item.name }}
        </el-radio>
      </el-radio-group>
    </div>

    <div class="shaixuan-card" v-if="categoryFindObj.popularBrandsList.length">
      <span class="sort-bya-title">{{ $t('Popular Brands') }}</span>
      <el-checkbox-group v-model="filtrationData.popularBrands" @change="handleChange('popularBrands', $event)" data-remark="Popular Brands品牌多选">
        <el-checkbox v-for="(item, index) in categoryFindObj.popularBrandsList" :key="index" :label="item">
          {{ item.name }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .page-box {
    position: relative;
    width: 286px;
    min-height: 400px;
    .shaixuan-card {
      display: grid;
      border-bottom: 1px solid #dcdfe6;
      margin-bottom: 12px;
      padding-bottom: 12px;
      width: 100%;
      .sort-bya-title {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #303133;
        text-transform: uppercase;
        margin-bottom: 16px;
      }
      .shaixuan_card_price {
        margin-bottom: 16px;
        .price-input {
          border-radius: 4px;
          width: 112px;
          margin-left: 12px;
          span {
            line-height: 34px;
            margin-left: 10px;
          }
          &:first-child {
            margin-left: 0px;
          }
          &::placeholder {
            color: #a8abb2;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
        .price-button {
          height: 30px;
          width: 39px;
          background: var(--mainBgColor);
          color: var(--mainTextColor);
          margin-left: 14px;
          cursor: pointer;
          border-radius: 8px;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .shaixuan-card {
    .el-radio-group {
      display: grid;
      gap: 4px;
      :deep(.el-radio__input.is-checked .el-radio__inner) {
        background-color: var(--Zcolor);
        border-color: var(--Zcolor);
      }
      :deep(.el-radio__inner:hover) {
        border-color: var(--Zcolor);
      }
      :deep(.el-radio__input.is-checked + .el-radio__label) {
        color: #303133;
      }
      :deep(.el-radio__label) {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        width: 250px;
        vertical-align: text-top;
        white-space: normal;
        overflow-wrap: break-word;
      }
    }
    .el-checkbox-group {
      display: grid;
      gap: 4px;
      :deep(.el-checkbox) {
        height: auto;
        min-height: 32px;
      }
      :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
        background-color: var(--Zcolor);
        border-color: var(--Zcolor);
      }
      :deep(.el-checkbox__inner:hover) {
        border-color: var(--Zcolor);
      }
      :deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
        color: #303133;
      }
      :deep(.el-checkbox__label) {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        width: 250px;
        /* text-align: justify; */
        vertical-align: text-top;
        white-space: normal;
        overflow-wrap: break-word;
      }
    }
  }
</style>
