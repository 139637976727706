<script setup>
  import Page from '@/components/Page'
  import categoryFind from './compontent/category-find'
  import listBox from './compontent/list-box'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch, nextTick, provide } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore, HsystemStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const categoryFindRef = ref()
  const listBoxRef = ref()
  const data = reactive({
    queryParms: {},
    loading: false,
    productsLoading: true,
    categoryLoading: false
  })

  // 初始化
  const init = async () => {
    await nextTick()
    if (data.queryParms.homeModeId) {
      return homeViewAllList()
    }
    getList()
  }

  const homeViewAllList = () => {
    listBoxRef.value.getDiscountsList(data.queryParms.homeModeId)
  }

  // 更改loading状态
  const setLoading = (statusObj) => {
    if (statusObj.type === 'list') {
      data.productsLoading = statusObj.status
    } else if (statusObj.type === 'category') {
      data.categoryLoading = statusObj.status
    }
    data.loading = data.productsLoading && data.categoryLoading
  }

  watch(useRoute(), (newValue, oldValue) => {
    console.log(newValue, oldValue, 'url发生变化')
    data.queryParms = newValue.query
    init()
  })

  // 常规列表查询
  const getList = (type) => {
    type = type || 'init'
    if (categoryFindRef.value) {
      categoryFindRef.value.getCategoryFind(type)
    }
    listBoxRef.value.getList()
  }

  onMounted(() => {
    data.queryParms = useRoute().query
    console.log(data.queryParms, 'queryParms')
    // console.log(useRoute(), 'router')
    init()
  })

  provide('setLoading', setLoading)
</script>

<template>
  <Page class="page-box">
    <JLImg v-if="userStore.isDev && 0" class="banner" src="faa1bdd0.png"></JLImg>
    <div>
      <div></div>
      <div></div>
    </div>
    <div class="list-content rowBS" v-loading="data.loading">
      <category-find v-if="userStore.isDev" ref="categoryFindRef" class="category-find-box" :queryParms="data.queryParms" @updataList="getList"></category-find>
      <list-box ref="listBoxRef" class="list-box-box" :queryParms="data.queryParms"></list-box>
    </div>
  </Page>
</template>

<style lang="less" scoped>
  .page-box {
    .banner {
      width: 1200px;
      height: 400px;
    }
    .list-content {
      width: 100%;
      min-height: 400px;
      margin-top: 24px;
      .category-find-box {
        flex: 0 0 286px;
        margin-right: 10px;
      }
      .list-box-box {
        /* flex: auto; */
        /* width: fit-content;*/
        flex: 1;
      }
    }
  }
</style>
